<template>

  <transition name="fade">
    <div class="modal-wrapper" v-if="this.isshow" :class="{ open: this.isshow }" @click="onClose">

      <div class="modal-container">

        <div class="modal-content">

          <div class="modal" @click.stop>

            <button type="button" class="modal-btn-close" @click="onClose">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                <path d="M1 1L13 13" stroke-width="2" stroke-linecap="round"/>
                <path d="M13 1L1 13" stroke-width="2" stroke-linecap="round"/>
                </g>
              </svg>
            </button>

            <slot name="content">

            </slot>

          </div>

        </div>

      </div>

    </div>
  </transition>

</template>

<script>
import src from "../controllers/components/ModalCtrl";
export default src;
</script>