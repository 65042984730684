
export default {
  name: 'IVideo',
  props: {
		videoAutoplay: {
			type: Boolean,
			default: false
		},
    videoSrc: String,
    videoPosterSrc: String,
		videoHd: Boolean,
		videoStar: {
			type: Boolean,
			default: false
		},
		videoLive: Boolean,
		videoUsersWatching: Number,
		videoModelName: String,
		videoModelAge: Number,
		videoModelCountry: {
			validator(value) {
        // The value must match one of these strings
        return ['UA', 'RU', 'PL', 'PL2'].includes(value)
      }
		},
		videoTypeCard: {
      type: Boolean,
			default: false,
    },
		videoChat: {
      type: Boolean,
			default: false,
		},
		videoControl: {
      type: Boolean,
			default: false,
		},
		videoCardTitle: {
			type: String,
			default: '',
		},
		videoCardSubtitle: {
			type: String,
			default: '',
		},
		videoCardTxt: {
			type: String,
			default: '',
		}
  },
	mounted() {
		if(this.videoAutoplay) {

			if(!this.$refs.videoRef.load) {
				this.$refs.videoRef.load();
			}

			this.$refs.videoRef.play();
		}
  },

	methods:{
		videoPlay() {

			this.isActive = !this.isActive;

			if(!this.$refs.videoRef.load) {
				this.$refs.videoRef.load();
			}
			if(this.$refs.videoRef.load) {
				this.$refs.videoRef.play();
			}
		},
		videoPause() {
			if(this.videoAutoplay == true){

				return false
			}
			if(this.$refs.videoRef.play) {
				this.$refs.videoRef.pause();
			}
		},
		clearValue(e) {
      e.target.value = "";
    },
	},
	data() {
    return {
			isActive: false,
    }
  },
}