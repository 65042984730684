<template>

	<div class="video-wrapp" :class="{active: isActive}" @mouseleave="isActive = false">

		<div class="video-card" v-if="videoTypeCard">
			<div class="video-card-title" v-if="videoCardTitle !== ''">{{ videoCardTitle }}</div>
			<div class="video-card-txt" v-if=" videoCardTxt !== ''">
				{{  videoCardTxt }}
			</div>
			<strong class="video-card-subtitle" v-if="videoCardSubtitle !== ''">{{ videoCardSubtitle }}</strong>
		</div>

		<div v-else>

			<div class="video-hd" v-if="videoHd">HD+</div>

			<div class="video-live" v-if="videoLive">LIVE</div>

			<div class="video-watching" v-if="videoUsersWatching">{{videoUsersWatching}} <img src="../assets/images/icons/eye.png" alt="eye"></div>

			<ul class="video-model-info" v-if="videoModelCountry || videoModelAge">

				<li v-if="videoStar">
          <img src="@/assets/images/icons/star-yellow.png" alt="star" width="13"/>
				</li>

				<li v-if="videoModelName"><span class="video-model-name">{{ videoModelName }},</span></li>

				<li v-if="videoModelAge"> {{ videoModelAge }} </li>

				<li v-if="videoModelCountry === 'UA'"> <img :src="require(`@/assets/images/icons/ukraine.png`)" alt="UA"></li>
				<li v-else-if="videoModelCountry === 'RU'"><img :src="require(`@/assets/images/icons/russia.png`)" alt="RU"></li>
				<li v-else-if="videoModelCountry === 'PL'"><img :src="require(`@/assets/images/icons/poland.png`)" alt="PL"></li>
				<li v-else-if="videoModelCountry === 'PL2'"><img :src="require(`@/assets/images/icons/poland2.png`)" alt="PL"></li>

			</ul>

			<img src="@/assets/images/preloader-oval.png" alt="preloader" class="video-preloader">

			<video :poster="videoPosterSrc" preload="auto" loop :muted="true" :autoplay="videoAutoplay" allow="autoplay" AutoplayAllowed AutoplayAllowlist fullscreen
			class="video" @mouseenter="videoPlay" @mouseleave="videoPause" ref="videoRef">
				<source :src="videoSrc">
				<!-- {{ videoSrc }} -->
				Your browser does not support the video or formats.
			</video>

			<div class="video-actions" v-if="videoChat || videoControl">

				<div class="video-control">
					<button type="button" class="btn">
						<img src="@/assets/images/icons/dots-vertical.svg" alt="gift">
					</button>
					<button type="button" class="btn">
						<img src="@/assets/images/icons/volume.svg" alt="gift">
					</button>
					<button type="button" class="btn">
						<img src="@/assets/images/icons/setting.svg" alt="gift">
					</button>
				</div>

				<div class="video-chat-form">

					<div class="video-chat-gifts">

						<span class="video-chat-gifts-txt">Gifts:</span>

						<button type="button" class="btn">
							<img src="@/assets/images/gifts/gift1.png" alt="gift">
						</button>
						<button type="button" class="btn">
							<img src="@/assets/images/gifts/gift2.png" alt="gift">
						</button>
						<button type="button" class="btn">
							<img src="@/assets/images/gifts/gift3.png" alt="gift">
						</button>
						<button type="button" class="btn">
							<img src="@/assets/images/gifts/gift4.png" alt="gift">
						</button>
						<button type="button" class="btn">
							<img src="@/assets/images/gifts/gift5.png" alt="gift">
						</button>
						<button type="button" class="btn">
							<img src="@/assets/images/gifts/gift6.png" alt="gift">
						</button>
					</div>

					<textarea placeholder="Enter your message" @blur="clearValue" @keydown.enter="clearValue"></textarea>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import src from "../controllers/components/VideoCtrl";
export default src;
</script>