<template>

	<footer class="footer">

		<div class="footer-top">

			<div class="container">

					<div class="footer-top-desc">
						<p>
							VictoriyaLive.com 2008-2021 © All rights reserved
						</p>
					</div>

					<ul class="footer-list-certificates">
						<li><img src="@/assets/images/certificates/norton.svg" alt="norton"></li>
						<li><img src="@/assets/images/certificates/pci.svg" alt="pci"></li>
						<li><img src="@/assets/images/certificates/visa.svg" alt="visa"></li>
						<li><img src="@/assets/images/certificates/mastercard.svg" alt="mastercard"></li>
					</ul>

				</div>

		</div>

	</footer>

</template>

<script>
export default {
	name: "IFooter",
};
</script>