import { createApp } from 'vue';
import App from '@/App.vue';

// router
import router from '@/router';

// plugins
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

// assets
import '@/assets/styles/modal.scss';
import '@/assets/styles/modal-registration.scss';
import '@/assets/styles/modal-agreement.scss';

import '@/assets/styles/footer.scss';

import '@/assets/styles/video.scss';
import '@/assets/styles/video-slider.scss';

import '@/assets/styles/page-error.scss';

import '@/assets/styles/main.scss';
import '@/assets/styles/breakpoints.scss';

// plugins
import VueSplide from '@splidejs/vue-splide';

// Custom packages
import IFooter from '@/components/Footer.vue';

import IVideo from '@/components/Video.vue';

import IModal from '@/components/Modal.vue';


const app = createApp(App);

// Custom packages
app.component('IFooter', IFooter);

app.component('IVideo', IVideo);

app.component('IModal', IModal);

// plugins
app.use(VueSplide);


// router
app.use(router);

app.mount('#app');
