<template>

    <section class="section-error">

        <div class="box-error">

            <h1 class="error-code">404</h1>

            <div class="error-messages">
                ERROR
            </div>

            <div class="error-txt">
                Page not found...
            </div>

            <router-link :to="{ name: 'Home' }" class="btn btn-lg btn-green">Back to Main</router-link>

        </div>

    </section>

</template>
